<template>
  <MainContentCard :animated :flat="pageType === 'modal'">
    <!-- Main Column -->
    <MainContent
      :class="{
        'min-h-full': page.centered,
      }"
      items="center"
      :flat="pageType === 'modal'"
      :justify="page.centered ? 'center' : 'start'"
      :key="page.slug"
      :width="page.fullWidth ? 'full' : 'md'"
    >
      <slot name="mainPrefix" />

      <!-- MAIN COMPONENTS -->
      <FlowComponent
        v-for="config in mainComponents"
        :config
        :data
        :deps
        :id="`br-main-component-${page.slug}-${config.name}`"
        :key="config.name"
        @action="onAction"
        @scroll-to="onScrollTo"
        @submit="(data) => emit('submit', data)"
        @validity-change="(valid: boolean) => onValidityChange(valid)"
      />
    </MainContent>

    <!-- Aside Column -->
    <template v-if="hasAsideComponents">
      <AsideContent v-if="!$isMobile.value" class="justify-between">
        <slot name="asidePrefix" />

        <AtroContent class="w-full space-y-4" direction="col" wrap="nowrap">
          <FlowComponent
            v-for="config in asideComponents"
            :config
            :data
            :deps
            :id="`br-aside-component-${page.slug}-${config.name}`"
            :key="config.name"
            @action="onAction"
            @validity-change="(valid: boolean) => onValidityChange(valid)"
          />
        </AtroContent>
      </AsideContent>

      <ModalMobileFlowAside
        v-else
        :data
        :deps
        :components="asideComponents"
        :is-open="mobileAsideModalOpen"
        @action="onAction"
        @close="mobileAsideModalOpen = false"
      />
    </template>
  </MainContentCard>
</template>

<script setup lang="ts">
export interface Props {
  page: Page
  pageType: 'main' | 'modal'

  animated?: boolean
  data?: Record<string, any>
  deps?: Record<string, any>
  width?: 'md' | 'lg' | 'full'
}

const { page, pageType, animated = true } = defineProps<Props>()
const emit = defineEmits<{
  action: [action: FlowAction, page: Page]
  submit: [data: any]
}>()

const { mobileAsideModalOpen, pageFocus, scrollingEl, valid } = usePageState()
const { y: scrollY } = useScroll(scrollingEl, { behavior: 'smooth' })

const dataPoll = ref()

const asideComponents = computed(() => page?.asideComponents || [])
const mainComponents = computed(() => page?.mainComponents || [])
const hasAsideComponents = computed(() => !!asideComponents.value.length)
const isFullHeight = computed(
  () =>
    !!mainComponents.value?.find(
      (componentConfig) => componentConfig.type === 'editor',
    ),
)
const isDataPending = computed(
  () =>
    !!mainComponents.value?.find(
      (componentConfig) =>
        componentConfig.type === 'status_card' &&
        componentConfig.props.status === 'pending',
    ),
)

function onAction(action: FlowAction) {
  emit('action', action, page)
}

function onScrollTo({ y = 0 }: { x?: number; y?: number }) {
  const currentScroll = scrollingEl.value?.scrollTop || 0
  scrollY.value = y + currentScroll - 420
}

function onValidityChange(validity: boolean) {
  if (pageFocus.value === pageType) valid.value = validity
}

watch(
  isDataPending,
  (updated) => {
    clearInterval(dataPoll.value)
    if (updated && import.meta.client) {
      dataPoll.value = setInterval(refreshNuxtData, 1000)
    }
  },
  { immediate: true },
)

onBeforeUnmount(() => {
  clearInterval(dataPoll.value)
})
</script>
