export default function useCurrentOrg() {
  const router = useRouter()
  const { orgs } = useUserOrgs()

  const isChildOrg = computed(() => !!org.value?.parent)
  const isKioskMode = computed(() => !!org.value?.kioskOrgFlowId)

  const org = computed(() => {
    const slug =
      router.currentRoute.value.params.slug ||
      router.currentRoute.value.query.org
    if (slug) {
      return orgs.value?.find((_org) => _org.slug === slug)
    } else if (orgs.value.length === 1) {
      return orgs.value[0]
    }
  })

  const securityAssessmentLink = computed(
    () =>
      `${window?.location.origin}/security-assessment?partner=${org.value?.slug}`,
  )

  const rootPath = computed(() =>
    org.value ? `/orgs/${org.value?.slug}` : '/',
  )

  return { isChildOrg, isKioskMode, org, securityAssessmentLink, rootPath }
}
