<template>
  <AtroPopover
    v-if="hasPopover"
    shown
    :placement
    :reference
    ref="popperRef"
    theme="onboarding"
    :key="currentStep.key"
    :floating-class="['z-10', currentStep.class]"
    :no-arrow="!currentStep.targetSelector"
    :offset="currentStep.offset"
  >
    <template #content>
      <AtroContent class="max-w-sm" direction="col" items="center">
        <AtroCloseButton
          class="!absolute -right-10 -top-10 mr-1 mt-1"
          type="modal"
          color="light"
          @click="() => confirmSkipWalkthrough()"
        />
        <AtroContent class="w-full gap-1" direction="col">
          <AtroHeading
            semibold
            class="text-white"
            size="xs"
            :text="currentStep.content.title"
          />
          <AtroParagraph
            v-if="currentStep.content.text"
            class="font-normal text-white/75"
            size="sm"
            :text="currentStep.content.text"
          />
        </AtroContent>
        <AtroButton
          v-if="currentStep.content.next"
          class="mt-4 place-self-start"
          type="dark"
          :icon-right="buttonIcon"
          :text="buttonText"
          @click="markStepAsSeen(currentStep)"
        />
      </AtroContent>
    </template>
  </AtroPopover>
</template>

<script setup lang="ts">
const { openModal } = useModal()
const { setOnboardingState, walkthroughSteps, walkthroughStepsSeen } =
  useOnboarding()

const isShown = ref(false)
const popperRef = ref()
const reference = ref<Element | null>()

const buttonIcon = computed<IconName>(() =>
  isLastWalkthroughStep.value ? 'check' : 'chevron-right',
)
const buttonText = computed(() =>
  isLastWalkthroughStep.value ? 'Done' : 'Next',
)
const activeWalkthroughSteps = computed(() =>
  walkthroughSteps.value.filter(
    (step) => !walkthroughStepsSeen.value.includes(step.key),
  ),
)
const currentStep = computed(() => activeWalkthroughSteps.value?.[0])
const hasPopover = computed(
  () => currentStep.value && isCurrentStepPopover.value && isShown.value,
)
const isCurrentStepPopover = computed(
  () => !currentStep.value.type || currentStep.value.type === 'popover',
)
const isLastWalkthroughStep = computed(() => currentStep.value.final)
const placement = computed(() => {
  return (
    currentStep.value.placement ||
    (!currentStep.value.targetSelector && 'bottom-end')
  )
})

function attachReference() {
  if (currentStep.value.targetSelector) {
    reference.value = document.querySelector(currentStep.value.targetSelector)
    if (reference.value && currentStep.value.closeOn) {
      switch (currentStep.value.closeOn) {
        case 'target:click':
          reference.value.addEventListener('click', () => {
            if (currentStep.value) markStepAsSeen(currentStep.value)
          })
          break
        default:
          break
      }
    }
  } else {
    reference.value = document.querySelector('#top-right')
  }
}

function confirmSkipWalkthrough(onConfirm?: () => void) {
  openModal('confirm', {
    props: {
      headingText: 'Skip walkthrough?',
      iconName: null,
      onConfirm: async () => {
        await setOnboardingState('walkthroughStatus', 'skipped')
        if (onConfirm) onConfirm()
        return true
      },
    },
  })
}

function hide() {
  isShown.value = false
  reference.value = null
}

function markStepAsSeen(step: OnboardingWalkthroughStep) {
  if (!walkthroughStepsSeen.value.includes(step.key)) {
    setOnboardingState('walkthroughStepsSeen', [
      ...walkthroughStepsSeen.value,
      step?.key,
    ])
    if (step.final) setOnboardingState('walkthroughStatus', 'completed')
    hide()
  }
}

async function update() {
  if (currentStep.value) {
    if (!reference.value) {
      attachReference()
      if (reference.value) {
        isShown.value = true
        return
      }
    } else if (
      // Check for remaining existance of target
      currentStep.value.targetSelector
        ? !!document.querySelector(currentStep.value.targetSelector)
        : true
    ) {
      isShown.value = true
      return
    }
  }
  if (isShown.value) {
    if (currentStep.value) markStepAsSeen(currentStep.value)
    else hide()
  }
}

let isAliveInterval: NodeJS.Timeout
onMounted(() => {
  isAliveInterval = setInterval(update, 100)
})
onUnmounted(() => {
  clearInterval(isAliveInterval)
})
watch(currentStep, (newStep, oldStep) => {
  if (oldStep && newStep && oldStep?.key !== newStep?.key)
    markStepAsSeen(oldStep)
  if (currentStep.value?.type === 'modal') {
    openModal('onboardingWalkthroughStep', {
      singleton: true,
      props: {
        step: currentStep.value,
        onNext: (close: () => void) => {
          markStepAsSeen(currentStep.value)
          close()
        },
        onSkip: confirmSkipWalkthrough,
      },
    })
  }
})
</script>
