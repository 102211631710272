<template>
  <SubscribeBanner v-if="showSubscribeBanner" />
  <div
    :class="[
      'pointer-events-none fixed right-4',
      { 'top-12': showSubscribeBanner, 'top-4': !showSubscribeBanner },
    ]"
    id="top-right"
  />

  <NuxtLayout :key="id">
    <NuxtPage v-if="!error" />
  </NuxtLayout>

  <!-- CLIENT ONLY COMPONENTS -->
  <ClientOnly>
    <!-- GLOBAL COMPONENTS -->
    <MobileNavDrawer v-if="includeMobileNavDrawer" />
    <ModalConfirmNavigation />
    <!-- GENERAL PURPOSE MODAL OUTLET -->
    <Modal />

    <!-- CARD OPEN ANIMATION -->
    <Transition
      enter-active-class="transition-opacity duration-100"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <template v-if="flowCardTransitionConfig">
        <FlowCardTransitional v-bind="flowCardTransitionConfig" />
      </template>
    </Transition>

    <!-- TOAST CONTAINER -->
    <Toaster offset="0px" position="top-center" />

    <PopoverWalkthrough v-if="isWalkthroughActive" />
  </ClientOnly>
</template>

<script setup lang="ts">
useElectronApi()
const id = useId()
const error = useError()
const router = useRouter()
const globalState = useGlobalState()
const isMobile = useIsMobile()
const { isWebContext } = useClientContext()
const { showSubscribeBanner } = useCurrentSubscription()
const { hasJoined } = useCurrentUser()
const { flowCardTransitionConfig } = useFlowCardTransition()
const { isWalkthroughActive } = useOnboarding()
const { pageIsDirty } = usePageDirtyTracking()

const includeMobileNavDrawer = computed(
  () => isMobile.value && hasJoined.value && isWebContext.value,
)

useHead({
  bodyAttrs: {
    class: computed(() => {
      const classes = []
      if (router.currentRoute.value.meta.autoHeight) {
        classes.push('auto-height')
      }
      if (
        globalState.value.appBannerShown &&
        !globalState.value.upgradeModalShown
      )
        classes.push('has-app-banner')
      if (globalState.value.bottomToolbarActive) {
        classes.push('has-bottom-toolbar')
      }
      if (globalState.value.modalStack.length) {
        classes.push('has-modal-open')
      }
      return classes
    }),
  },
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Atro` : 'Atro'
  },
})

onMounted(() => {
  window.onbeforeunload = () => {
    if (pageIsDirty.value) {
      return 'You have attempted to leave this page. Are you sure?'
    }
  }
})
</script>
